/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useAuthContext } from '../../../../context/auth.context'
import { useDashboardContext } from '../../../../context/dashboard.context'
import { dashboardConst } from '../../../../utils/action.constant'
import { useLoadPeriodicSummary } from '../../../../hooks/dashboard.hooks'
import moment from 'moment'
import { BiDownload } from 'react-icons/bi'
import {
  Box,
  Divider,
  Flex,
  Skeleton,
  Stack,
  Text,
  Icon,
  VStack,
  Tooltip
} from '@chakra-ui/react'
import DrillDownTable, {
  TH,
  TR,
  TD,
} from '../../../../components/dashboard-table'
import { calculateStartEndDate } from '../../../../utils/dashboard.utils'
import ReactExport from 'react-data-export'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../repository/repo.utils'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function WidgetPeriodicSummary() {
  const {
    state: { queryFilters, sipReporteeList },
    dispatch: dashboardDispatch,
  } = useDashboardContext()
  const {
    state: { authData },
  } = useAuthContext()
  const [download, setDownload] = useState(false)

  const mainTable = queryFilters?.selectedSipSegment?.value === 358 ? 'verticals' : 'clusters'

  const {
    mutate: mutatePeriodicSummaryRoles,
    data: periodicSummaryRolesData,
    isLoading: isRolesLoading,
    isSuccess: isRolesSuccess
  } = useLoadPeriodicSummary()

  const {
    mutate: mutatePeriodicSummaryRoleTargets,
    data: periodicSummaryRoleTargets,
    isLoading: isRoleTargetsLoading,
    isSuccess: isRoleTargetsSuccess
  } = useLoadPeriodicSummary()

  const {
    mutate: mutatePeriodicSummaryVerticals,
    data: periodicSummaryVerticalsData,
    isLoading: isVerticalsLoading,
    isSuccess: isVerticalsSuccess
  } = useLoadPeriodicSummary()

  const {
    mutate: mutatePeriodicSummaryVerticalTargets,
    data: periodicSummaryVerticalTargets,
    isLoading: isVerticalTargetsLoading,
    isSuccess: isVerticalTargetsSuccess
  } = useLoadPeriodicSummary()

  const {
    mutate: mutatePeriodicSummaryClusters,
    data: PeriodicSummaryClustersData,
    isLoading: isClusterLoading,
    isSuccess: isClusterSuccess
  } = useLoadPeriodicSummary()

  const {
    mutate: mutatePeriodicSummaryClusterTargets,
    data: PeriodicSummaryClusterTargets,
    isLoading: isClusterTargetsLoading,
    isSuccess: isClusterTargetsSuccess
  } = useLoadPeriodicSummary()

  useEffect(() => {
    getSegmentBasedSummary()
  }, [
    queryFilters.selectedCreator,
  queryFilters.selectedPeriod,
  queryFilters.selectedPeriodType,
  queryFilters.selectedSipSegment,
  queryFilters.selectedYear,
  queryFilters.selectedCluster,
  queryFilters.selectedRole,
  queryFilters.selectedVertical
])

  const getSegmentBasedSummary = () => {
    const { selectedSipSegment, selectedCreator } = queryFilters
    let reportee = { ...queryFilters.selectedCreator }
    if (reportee?.label === 'All') {
      reportee = { ...authData }
      queryFilters.selectedCreator = {label: authData.operating_asset_first_name, asset_id :authData.asset_id}
    } 
    //On SA retrieve Roles and Verticals 
    if (!!queryFilters.selectedSipSegment && queryFilters.selectedSipSegment.value === 358) {
      loadPeriodicSummaryRoles(reportee, 311)
      loadPeriodicSummaryRoleTargets(reportee, 312)
      loadPeriodicSummaryVerticals(reportee, 313)
      loadPeriodicSummaryVerticalTargets(reportee, 314)
    }
    else if (!!queryFilters.selectedSipSegment && queryFilters.selectedSipSegment.value === 185) {
      loadPeriodicSummaryRoles(reportee, 321)
      loadPeriodicSummaryRoleTargets(reportee, 322)
      loadPeriodicSummaryVerticals(reportee, 323)
      loadPeriodicSummaryVerticalTargets(reportee, 324)
    }
    else {
      //SME case is yet to start
      loadPeriodicSummaryRoles(reportee, 331)
      loadPeriodicSummaryRoleTargets(reportee, 332)
      loadPeriodicSummaryClusters(reportee, 333)
      loadPeriodicSummaryClusterTargets(reportee, 334)

    }
  }
  const loadPeriodicSummaryRoles = (reportee, subFlag) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedYear,
      selectedSipSegment,
      selectedVertical,
      selectedCluster,
      selectedRole
    } = queryFilters
    mutatePeriodicSummaryRoles(
      {
        // manager_asset_id: 40464,
        // datetime_start: startDate,
        // datetime_end: endDate,
        // workforce_tag_id: selectedSipSegment?.value,
        // timeline_flag: selectedPeriodType?.value,
        // sub_timeline_flag: selectedPeriod?.value,
        // page_start: 0,
        // page_limit: 50,
        // year: selectedYear?.value,

        flag: 3,
        segment: queryFilters.selectedSipSegment?.value,//!!selectedSipSegment? selectedSipSegment.value : 0,
        subFlag,
        period_type_id: selectedPeriod?.value || 1,
        year: !!selectedYear ? selectedYear.value : '',
        vertical: !!selectedVertical ? selectedVertical.value : 0,
        cluster: !!selectedCluster ? selectedCluster.value : 0,
        role: !!selectedRole ? selectedRole.value : 0,
        timeline: selectedPeriodType?.value || 0,
        reportee: reportee.asset_id
      },
      {
        onSuccess: data => {
          console.log(data)
        },
      }
    )
    // }
  }

  const loadPeriodicSummaryRoleTargets = (reportee, subFlag) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedYear,
      selectedSipSegment,
      selectedVertical,
      selectedCluster,
      selectedRole
    } = queryFilters
    mutatePeriodicSummaryRoleTargets(
      {
        // manager_asset_id: 40464,
        // datetime_start: startDate,
        // datetime_end: endDate,
        // workforce_tag_id: selectedSipSegment?.value,
        // timeline_flag: selectedPeriodType?.value,
        // sub_timeline_flag: selectedPeriod?.value,
        // page_start: 0,
        // page_limit: 50,
        // year: selectedYear?.value,

        flag: 3,
        segment: selectedSipSegment?.value,//!!selectedSipSegment? selectedSipSegment.value : 0,
        vertical_tag_id: 0,
        cluster_id: 0,
        subFlag,
        period_type_id: selectedPeriod?.value || 1,
        year: !!selectedYear ? selectedYear.value : '',
        vertical: !!selectedVertical ? selectedVertical.value : 0,
        cluster: !!selectedCluster ? selectedCluster.value : 0,
        role: !!selectedRole ? selectedRole.value : 0,
        timeline: selectedPeriodType?.value || 0,
        reportee: reportee.asset_id
      },
      {
        onSuccess: data => {
          console.log(data)
        },
      }
    )
    // }
  }

  const loadPeriodicSummaryVerticals = (reportee, subFlag) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedYear,
      selectedSipSegment,
      selectedVertical,
      selectedCluster,
      selectedRole
    } = queryFilters

    mutatePeriodicSummaryVerticals(
      {
        flag: 3,
        segment: queryFilters.selectedSipSegment?.value,//!!selectedSipSegment? selectedSipSegment.value : 0,
        subFlag,
        year: !!selectedYear ? selectedYear.value : '',
        vertical: !!selectedVertical && queryFilters.selectedSipSegment?.value === 358 ? selectedVertical.value : 0,
        cluster: !!selectedCluster && queryFilters.selectedSipSegment?.value !== 358 ? selectedCluster.value : 0,
        role: !!selectedRole ? selectedRole.value : 0,
        period_type_id: selectedPeriod?.value || 1,
        timeline: selectedPeriodType?.value || 0,
        reportee: reportee.asset_id
      },
      {
        onSuccess: data => {
          console.log(data)
        },
      }
    )
  }

  const loadPeriodicSummaryVerticalTargets = (reportee, subFlag) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedYear,
      selectedSipSegment,
      selectedVertical,
      selectedCluster,
      selectedRole
    } = queryFilters

    mutatePeriodicSummaryVerticalTargets(
      {
        flag: 3,
        segment: queryFilters.selectedSipSegment?.value,//!!selectedSipSegment? selectedSipSegment.value : 0,
        subFlag,
        year: !!selectedYear ? selectedYear.value : '',
        vertical: !!selectedVertical ? selectedVertical.value : 0,
        cluster: !!selectedCluster ? selectedCluster.value : 0,
        role: !!selectedRole ? selectedRole.value : 0,
        period_type_id: selectedPeriod?.value || 1,
        timeline: selectedPeriodType?.value || 0,
        reportee: reportee.asset_id
      },
      {
        onSuccess: data => {
          console.log(data)
        },
      }
    )
  }

  const loadPeriodicSummaryClusters = (reportee, subFlag) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedYear,
      selectedSipSegment,
      selectedVertical,
      selectedCluster,
      selectedRole
    } = queryFilters

    mutatePeriodicSummaryClusters(
      {
        flag: 3,
        segment: queryFilters.selectedSipSegment?.value,//!!selectedSipSegment? selectedSipSegment.value : 0,
        subFlag,
        year: !!selectedYear ? selectedYear.value : '',
        vertical: !!selectedVertical && queryFilters.selectedSipSegment?.value === 358 ? selectedVertical.value : 0,
        cluster: !!selectedCluster && queryFilters.selectedSipSegment?.value !== 358 ? selectedCluster.value : 0,
        role: !!selectedRole ? selectedRole.value : 0,
        period_type_id: selectedPeriod?.value || 1,
        timeline: selectedPeriodType?.value || 0,
        reportee: reportee.asset_id
      },
      {
        onSuccess: data => {
          console.log(data)
        },
      }
    )
  }

  const loadPeriodicSummaryClusterTargets = (reportee, subFlag) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedYear,
      selectedSipSegment,
      selectedVertical,
      selectedCluster,
      selectedRole
    } = queryFilters

    mutatePeriodicSummaryClusterTargets(
      {
        flag: 3,
        segment: queryFilters.selectedSipSegment?.value,//!!selectedSipSegment? selectedSipSegment.value : 0,
        subFlag,
        year: !!selectedYear ? selectedYear.value : '',
        vertical: !!selectedVertical ? selectedVertical.value : 0,
        cluster: !!selectedCluster ? selectedCluster.value : 0,
        role: !!selectedRole ? selectedRole.value : 0,
        period_type_id: selectedPeriod?.value || 1,
        timeline: selectedPeriodType?.value || 0,
        reportee: reportee.asset_id
      },
      {
        onSuccess: data => {
          console.log(data)
        },
      }
    )
  }

  const getToolTipName = (kpiCode = '') => {
    return !!periodicSummaryRoleTargets && periodicSummaryRoleTargets.filter(el => el.widget_type_code === kpiCode).map(ele => ele.widget_type_name)
  }

  const calSubHeads = _arr => {
    return (
      _arr
        .filter(item => item)
        .map(item => ({
          name: item,
          rowSpan: 1,
          colSpan: 1,
        })) || [{ name: 'Parameter 1', rowSpan: 1, colSpan: 1 }]
    )
  }

  // Filtering unique dynamic target headers for roles
  let sip_roleTargets = !!periodicSummaryRoleTargets && periodicSummaryRoleTargets.length > 0 ?
    periodicSummaryRoleTargets.filter((targets) => {
      let targetsData = !!periodicSummaryRolesData && periodicSummaryRolesData.some(roles => targets.asset_type_id === roles.asset_type_id && targets.employee_role === roles.employee_role)
      return targetsData
    }) : []
  let unique_roleTarget = sip_roleTargets.filter((a, i) => sip_roleTargets.findIndex((s) =>
    a.widget_type_code === s.widget_type_code) === i)

  let role_target_headers = [...new Set(unique_roleTarget.map(item => item.widget_type_code))];

  // Filtering unique dynamic target headers for verticals

  let sip_verticalTargets = !!periodicSummaryVerticalTargets && periodicSummaryVerticalTargets.length > 0 ?
    periodicSummaryVerticalTargets.filter((targets) => {
      let targetsData = !!periodicSummaryVerticalsData && periodicSummaryVerticalsData.some(verticals => targets.vertical_tag_id === verticals.vertical_tag_id)
      return targetsData
    }) : []
  let unique_verticalTarget = sip_verticalTargets.filter((a, i) => sip_verticalTargets.findIndex((s) =>
    a.widget_type_code === s.widget_type_code) === i)

  let vertical_target_headers = [...new Set(unique_verticalTarget.map(item => item.widget_type_code))]

  // // Filtering unique dynamic target headers for clusters
  let sip_clusterTargets = !!PeriodicSummaryClusterTargets && PeriodicSummaryClusterTargets.length > 0 ?
  PeriodicSummaryClusterTargets.filter((targets) => {
      let targetsData = !!PeriodicSummaryClusterTargets && PeriodicSummaryClusterTargets.some(Clusters => targets.Cluster_tag_id === Clusters.Cluster_tag_id)
      return targetsData
    }) : []
  let unique_ClusterTarget = sip_clusterTargets.filter((a, i) => sip_clusterTargets.findIndex((s) =>
    a.widget_type_code === s.widget_type_code) === i)

  let cluster_target_headers = [...new Set(unique_ClusterTarget.map(item => item.widget_type_code))]

  const renderHeadings = type => {
    let heads = []
    let key =
      type === 'roles'
        ? 'role_summary'
        : type === 'verticals'
          ? 'vertical_summary'
          : 'cluster_summary'


    switch (type) {
      case 'roles': {
        heads = [
          { name: 'Segment', rowSpan: 2, colSpan: 1 },
          { name: 'Role', rowSpan: 2, colSpan: 1 },
          { name: 'Employees on SIP', rowSpan: 2, colSpan: 1 },
          { name: 'Qualified for SIP', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized SIP Penetration %', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized SIP Utilization %', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized % of Target Achieved', rowSpan: 2, colSpan: 1 },
          {
            name: '% of Target Achieved',
            rowSpan: 1,
            colSpan: calSubHeads(role_target_headers).length || 1,
            subHeader: calSubHeads(role_target_headers),
          },
          {
            name: '% of SIP Payout',
            rowSpan: 1,
            colSpan: calSubHeads(role_target_headers).length || 1,
            subHeader: calSubHeads(role_target_headers),
          },
          {
            name: 'SIP Qualifiers',
            rowSpan: 1,
            colSpan: calSubHeads(role_target_headers).length || 1,
            subHeader: calSubHeads(role_target_headers),
          },
        ]
        break
      }
      case 'verticals': {
        heads = [
          { name: 'Segment', rowSpan: 2, colSpan: 1 },
          { name: 'Vertical/Cluster', rowSpan: 2, colSpan: 1 },
          // { name: 'Cluster', rowSpan: 2, colSpan: 1 },
          { name: 'Employees on SIP', rowSpan: 2, colSpan: 1 },
          { name: 'Qualified for SIP', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized SIP Penetration %', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized SIP Utilization %', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized % of Target Achieved', rowSpan: 2, colSpan: 1 },
          {
            name: '% of Target Achieved',
            rowSpan: 1,
            colSpan: calSubHeads(vertical_target_headers).length || 1,
            subHeader: calSubHeads(vertical_target_headers),
          },
          {
            name: '% of SIP Payout',
            rowSpan: 1,
            colSpan: calSubHeads(vertical_target_headers).length || 1,
            subHeader: calSubHeads(vertical_target_headers),
          },
          {
            name: 'SIP Qualifiers',
            rowSpan: 1,
            colSpan: calSubHeads(vertical_target_headers).length || 1,
            subHeader: calSubHeads(vertical_target_headers),
          },
        ]
        break
      }
      case 'cluster': {
        heads = [
          { name: 'Segment', rowSpan: 2, colSpan: 1 },
          { name: 'Vertical/Cluster', rowSpan: 2, colSpan: 1 },
          // { name: 'Cluster', rowSpan: 2, colSpan: 1 },
          { name: 'Employees on SIP', rowSpan: 2, colSpan: 1 },
          { name: 'Qualified for SIP', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized SIP Penetration %', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized SIP Utilization %', rowSpan: 2, colSpan: 1 },
          { name: 'Summarized % of Target Achieved', rowSpan: 2, colSpan: 1 },
          {
            name: '% of Target Achieved',
            rowSpan: 1,
            colSpan: calSubHeads(vertical_target_headers).length || 1,
            subHeader: calSubHeads(vertical_target_headers),
          },
          {
            name: '% of SIP Payout',
            rowSpan: 1,
            colSpan: calSubHeads(vertical_target_headers).length || 1,
            subHeader: calSubHeads(vertical_target_headers),
          },
          {
            name: 'SIP Qualifiers',
            rowSpan: 1,
            colSpan: calSubHeads(vertical_target_headers).length || 1,
            subHeader: calSubHeads(vertical_target_headers),
          },
        ]
        break
      }
      default:
        return []
    }
    let mainHeader = heads.map(item => (
      <TH rowSpan={item.rowSpan} justify={"center"} colSpan={item.colSpan} key={item.name} classes ={'text-Wrap' }>
        {item.name}
      </TH>
    ))
    let tableHead = (
      <>
        <TR>
          <TH rowSpan={2}>#</TH>
          {mainHeader}
        </TR>
        <TR>
          {heads
            .filter(head => head.subHeader)
            .map(head =>
              head.subHeader.map(item => (
                <TH
                  rowSpan={item.rowSpan}
                  colSpan={item.colSpan}
                  key={item.name}
                >
                  <Tooltip
                    label={getToolTipName(item.name)}
                    fontSize="sm"
                    bg="secondary"
                    color="black.700"
                  >
                    <span> {item.name} </span>
                  </Tooltip>
                </TH>

              ))
            )}
        </TR>
      </>
    )
    return tableHead
  }

  const handleDownload = () => {
    setDownload(true)
  }

  function checkValid(parent, arr, key, head) {
    return (
      arr.find(
        item =>
          item?.[key] === parent?.[key] && head.includes(item.widget_type_name)
        // (head ? head === item.widget_type_code : true)
      ) || {}
    )
  }

  // function checkValidVertical(parent, arr, key, key1, head) {
  //   return (
  //     arr.find(
  //       item =>
  //         item?.[key1] === parent?.[key] &&
  //         (head ? head === item.kpi_activity_type_id : true)
  //     ) || {}
  //   )
  // }

  // function checkValidCircle(parent, arr, key, head) {
  //   return (
  //     arr.find(
  //       item =>
  //         item?.[key] === parent?.[key] &&
  //         (head ? head === item.kpi_activity_type_id : true)
  //     ) || {}
  //   )
  // }

  const exportHeadings = type => {
    let heads = []
    let key =
      type === 'roles'
        ? 'role_summary'
        : type === 'verticals'
          ? 'vertical_summary'
          : 'cluster_summary'
    // let sip_target_achieved_percent =
    //   periodicSummaryRolesData?.[key]?.sip_target_achieved_percent || []
    // let sip_payout_percent =
    //   periodicSummaryRolesData?.[key]?.sip_payout_percent || []
    // let sip_qualifiers = periodicSummaryRolesData?.[key]?.sip_qualifiers || []

    switch (type) {
      case 'roles': {
        heads = [
          'Segment',
          'Role',
          'Employees on SIP',
          'Qualified for SIP',
          'Summarized SIP Penetration %',
          'Summarized SIP Utilization %',
          'Summarized % of Target Achieved',
          ...role_target_headers.map(
            item => `% of Target Achieved_${getToolTipName(item)}`
          ),
          ...role_target_headers.map(
            item => `% of SIP Payout_${getToolTipName(item)}`
          ),
          ...role_target_headers.map(
            item => `SIP Qualifiers_${getToolTipName(item)}`
          ),
        ]
        return heads
      }
      case 'verticals': {
        heads = [
          'Segment',
          'Vertical/Cluster',
          'Employees on SIP',
          'Qualified for SIP',
          'Summarized SIP Penetration %',
          'Summarized SIP Utilization %',
          'Summarized % of Target Achieved',
          ...vertical_target_headers.map(name => `% of Target Achieved_${getToolTipName(name)}`),
          ...vertical_target_headers.map(
            item => `% of SIP Payout_${getToolTipName(item)}`
          ),
          ...vertical_target_headers.map(
            item => `SIP Qualifiers_${getToolTipName(item)}`
          ),
        ]
        return heads
      }
      case 'cluster': {
        heads = [
          'Segment',
          'Vertical/Cluster',
          'Employees on SIP',
          'Qualified for SIP',
          'Summarized SIP Utilization %',
          'Summarized % of Target Achieved',
          ...vertical_target_headers.map(name => `% of Target Achieved_${getToolTipName(name)}`),
          ...vertical_target_headers.map(
            item => `% of SIP Payout_${getToolTipName(item)}`
          ),
          ...vertical_target_headers.map(
            item => `SIP Qualifiers_${getToolTipName(item)}`
          ),
        ]
        return heads
      }
      default:
        return []
    }
  }

  const renderExportData = () => {
    let dataSetRole = makeTableData('roles')
    let dataSetMain = makeTableData(mainTable)
    const fileName = 'Periodic Summary Report'
    return (
      <ExcelFile filename={fileName} hideElement={true}>
        {[
          {
            sheet: 'Periodic Summary Role',
            data: dataSetRole,
            heads: exportHeadings('roles'),
          },
          {
            sheet: 'Periodic Summary Vertical/Cluster',
            data: dataSetMain,
            heads: exportHeadings(mainTable),
          },
        ].map(item => (
          <ExcelSheet data={item.data} name={item.sheet}>
            {item.heads?.map(headerItem => (
              <ExcelColumn label={headerItem} value={headerItem} />
            ))}
          </ExcelSheet>
        ))}
      </ExcelFile>
    )
  }

  const makeTableData = type => {
    let _arr = []
    let key =
      type === 'roles'
        ? 'role_summary'
        : type === 'verticals'
          ? 'vertical_summary'
          : 'circle_summary'

    // let sip_overall_achieved_percent =
    //   periodicSummaryRolesData?.[key]?.sip_overall_achieved_percent || []
    // let sip_emps = periodicSummaryRolesData?.[key]?.sip_employees || []
    // let sip_qualified_employees =
    //   periodicSummaryRolesData?.[key]?.sip_qualified_employees || []
    // let sip_utilization_percent =
    //   periodicSummaryRolesData?.[key]?.sip_utilization_percent || []
    // let sip_target_achieved_percent =
    //   periodicSummaryRolesData?.[key]?.sip_target_achieved_percent || []
    // let sip_payout_percent =
    //   periodicSummaryRolesData?.[key]?.sip_payout_percent || []
    // let sip_qualifiers = periodicSummaryRolesData?.[key]?.sip_qualifiers || []
    if (type === 'roles') {
      _arr = periodicSummaryRolesData || []
      _arr = _arr.map((item, i) => {
        let obj = {}
        obj['s.no'] = i + 1
        obj['Segment'] = queryFilters.selectedSipSegment?.label
        obj['Role'] = item.employee_role
        obj['Employees on SIP'] = item.employees_on_sip

        obj['Qualified for SIP'] = item.qualified_for_sip
        obj['Summarized SIP Penetration %'] = !!item.percentage_penetration ? item.percentage_penetration.toFixed(2) + '%' : '-'
        obj['Summarized SIP Utilization %'] = !!item.overall_util ? item.overall_util.toFixed(2) + '%' : 0
        obj[`Summarized % of Target Achieved`] = !!item.overall_ach ? item.overall_ach.toFixed(2) + '%' : 0

        let percentage_target_achieved = role_target_headers.map(name => `% of Target Achieved_${getToolTipName(name)}`)
        let percentage_sip_payout = role_target_headers.map(name => `% of SIP Payout_${getToolTipName(name)}`)
        let employees_on_sip = role_target_headers.map(name => `SIP Qualifiers_${getToolTipName(name)}`)

        percentage_target_achieved.forEach(code => {
          obj[code] = !!checkValid(item, sip_roleTargets, 'asset_type_id', code)
            .percentage_target_achieved ?
            parseFloat(checkValid(item, sip_roleTargets, 'asset_type_id', code).percentage_target_achieved).toFixed(2) + '%' : 'NA'

        })
        percentage_sip_payout.forEach(code => {
          obj[code] = !!checkValid(item, sip_roleTargets, 'asset_type_id', code)
            .percentage_sip_payout ?
            parseFloat(checkValid(item, sip_roleTargets, 'asset_type_id', code).percentage_sip_payout).toFixed(2)  + '%' : 'NA'
        })
        employees_on_sip.forEach(code => {
          obj[code] = !!checkValid(item, sip_roleTargets, 'asset_type_id', code)
            .employees_on_sip ?
            parseFloat(checkValid(item, sip_roleTargets, 'asset_type_id', code).employees_on_sip).toFixed(2) : 'NA'
        })

        // sip_payout_percent.forEach(x => {
        //   obj[`% of SIP Payout_${x.kpi_activity_type_name}`] = checkValid(
        //     item,
        //     sip_roleTargets,
        //     'asset_type_id',
        //     x.widget_type_code
        //   ).percentage_target_achieved
        // })
        // sip_payout_percent.forEach(x => {
        //   obj[`% of SIP Payout_${x.kpi_activity_type_name}`] = checkValid(
        //     item,
        //     sip_roleTargets,
        //     'asset_type_id',
        //     x.widget_type_code
        //   ).percentage_target_achieved
        // })
        // sip_qualifiers.forEach(x => {
        //   obj[`SIP Qualifiers_${x.kpi_activity_type_name}`] = checkValid(
        //     item,
        //     sip_qualifiers,
        //     'asset_type_id',
        //     x.kpi_activity_type_id
        //   ).payout_qualifiers
        // })
        return obj
      })
    } else if (type === 'verticals') {
      _arr = periodicSummaryVerticalsData || []
      console.log(periodicSummaryVerticalsData)
      _arr = _arr.map((item, i) => {
        let obj = {}
        obj['s.no'] = i + 1
        obj['Segment'] = queryFilters.selectedSipSegment?.label
        obj['Vertical/Cluster'] = !!queryFilters.selectedSipSegment && queryFilters.selectedSipSegment.value === 358 ? item.vertical_tag_name : queryFilters.selectedSipSegment.value === 185 ? item.cluster_name : ' - '
        // obj['Cluster'] = item.employee_cluster
        obj['Employees on SIP'] = parseInt(item.employees_on_sip)
        obj['Qualified for SIP'] = parseInt(item.qualified_for_sip)
        obj['Summarized SIP Penetration %'] = !!item.percentage_penetration ? item.percentage_penetration.toFixed(2) + '%' : '-'
        obj['Summarized SIP Utilization %'] = !!item.overall_util ? item.overall_util.toFixed(2) + '%' : '-'
        obj[`Summarized % of Target Achieved`] = !!item.overall_ach ? item.overall_ach.toFixed(2) + '%' : '-'

        let percentage_target_achieved = vertical_target_headers.map(name => `% of Target Achieved_${getToolTipName(name)}`)
        let percentage_sip_payout = vertical_target_headers.map(name => `% of SIP Payout_${getToolTipName(name)}`)
        let employees_on_sip = vertical_target_headers.map(name => `SIP Qualifiers_${getToolTipName(name)}`)

        percentage_target_achieved.forEach(code => {
          obj[code] = !!checkValid(item, sip_verticalTargets, 'vertical_tag_id', code)
            .percentage_target_achieved ?
            parseFloat(checkValid(item, sip_verticalTargets, 'vertical_tag_id', code).percentage_target_achieved).toFixed(2) + '%' : 'NA'

        })
        percentage_sip_payout.forEach(code => {
          obj[code] = !!checkValid(item, sip_verticalTargets, 'vertical_tag_id', code)
            .percentage_sip_payout ?
            parseFloat(checkValid(item, sip_verticalTargets, 'vertical_tag_id', code).percentage_sip_payout).toFixed(2) + '%' : 'NA'
        })
        employees_on_sip.forEach(code => {
          obj[code] = !!checkValid(item, sip_verticalTargets, 'vertical_tag_id', code)
            .employees_on_sip ?
            parseFloat(checkValid(item, sip_verticalTargets, 'vertical_tag_id', code).employees_on_sip).toFixed(2) : 'NA'
        })

        return obj
      })
    } else {
      _arr = PeriodicSummaryClustersData || []
      console.log(periodicSummaryVerticalsData)
      _arr = _arr.map((item, i) => {
        let obj = {}
        obj['s.no'] = i + 1
        obj['Segment'] = queryFilters.selectedSipSegment?.label
        obj['Vertical/Cluster'] = !!queryFilters.selectedSipSegment && queryFilters.selectedSipSegment.value === 358 ? item.vertical_tag_name : queryFilters.selectedSipSegment.value === 185 ? item.cluster_name : ' - '
        // obj['Cluster'] = item.employee_cluster
        obj['Employees on SIP'] = parseInt(item.employees_on_sip)
        obj['Qualified for SIP'] = parseInt(item.qualified_for_sip)
        obj['Summarized SIP Penetration %'] = !!item.percentage_penetration ? item.percentage_penetration.toFixed(2) + '%' : '-' 
        obj['Summarized SIP Utilization %'] = !!item.overall_util ? item.overall_util.toFixed(2) + '%' : '-'
        obj[`Summarized % of Target Achieved`] = !!item.overall_ach ? item.overall_ach.toFixed(2) + '%' : '-'

        let percentage_target_achieved = cluster_target_headers.map(name => `% of Target Achieved_${getToolTipName(name)}`)
        let percentage_sip_payout = cluster_target_headers.map(name => `% of SIP Payout_${getToolTipName(name)}`)
        let employees_on_sip = cluster_target_headers.map(name => `SIP Qualifiers_${getToolTipName(name)}`)

        percentage_target_achieved.forEach(code => {
          obj[code] = !!checkValid(item, sip_clusterTargets, 'vertical_tag_id', code)
            .percentage_target_achieved ?
            parseFloat(checkValid(item, sip_clusterTargets, 'vertical_tag_id', code).percentage_target_achieved).toFixed(2) + '%' : 'NA'

        })
        percentage_sip_payout.forEach(code => {
          obj[code] = !!checkValid(item, sip_clusterTargets, 'vertical_tag_id', code)
            .percentage_sip_payout ?
            parseFloat(checkValid(item, sip_clusterTargets, 'vertical_tag_id', code).percentage_sip_payout).toFixed(2) + '%' : 'NA'
        })
        employees_on_sip.forEach(code => {
          obj[code] = !!checkValid(item, sip_clusterTargets, 'vertical_tag_id', code)
            .employees_on_sip ?
            parseFloat(checkValid(item, sip_clusterTargets, 'vertical_tag_id', code).employees_on_sip).toFixed(2) : 'NA'
        })

        return obj
      })
    }
    return _arr
  }

  const parseNumber = (string) => {
   return isNaN(parseFloat(string)) ? 0 : parseFloat(string)
  }

  const verticalSum = data => {
    const arr = JSON.parse(
      JSON.stringify(data).replaceAll('%', '')
    )
    let sum = arr.reduce(
      (prev, cur, i) => i === 0 ? cur :
        Object.entries(cur).reduce(
          (ac, [key, value]) => ({ ...ac, [key]: (parseNumber(value) + parseNumber(prev[key])) }), {}
        ), {}
    )
    sum['s.no'] = 'Totals'
    sum['Segment'] = '-'
    sum['Vertical/Cluster'] = '-'
    Object.keys(sum).forEach((item) => {
      if(isNaN(sum[item] && !['s.no','Segment','Vertical/Cluster'].includes(item))) {
        sum[item] = 'NA'
      }
      else if(item.includes('SIP Payout') || item.includes('Target Achieved') || item.includes('SIP Utilization') || item.includes('SIP Penetration') ){
        sum[item] = sum[item].toFixed(2)+ '%'
      }
  })
  return sum

}


const renderData = type => {
  let data = makeTableData(type)
  let verticalTotal = {}
  if (type === 'verticals' && data.length > 1) {
    verticalTotal = verticalSum(data)
    data.push(verticalTotal)
  }
  return (data?.map((item, i) => (
    <TR key={i}>
      {Object.values(item).map((val,j) => (
        <TD classes = {j > 4 ? `text-right` : `text-center`}>{val}</TD>
      ))}
    </TR>
  ))

  )
}

const widgetMainView = isRolesLoading && isRoleTargetsLoading && isVerticalsLoading && isVerticalTargetsLoading ? (
  <Stack>
    <Skeleton height="20px" />
    <Skeleton height="20px" />
    <Skeleton height="20px" />
  </Stack>
) : (
  <Box>
    {/* <Flex mb={3} p={3} alignItems="center">
        {!!sipReporteeList &&
          sipReporteeList.map((item, i) => (
            <>
              {i !== 0 ? '/' : null}
              <Text
                fontWeight="bold"
                onClick={() => loadPeriodicSummary(item)}
                color={localStorage.getItem('color')}
                cursor="pointer"
                textDecoration="underline"
              >
                {' '}
                {item.operating_asset_first_name}{' '}
              </Text>
            </>
          ))}
      </Flex> */}
    <VStack spacing={8}>
      <Box w="100%">
        <Icon
          as={BiDownload}
          w={6}
          h={6}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_DASHBOARD,
              action: 'Widget Periodic Summary',
              label: 'Download',
            })
            handleDownload()
          }}
          className={`cursor-pointer`}
        />
        &nbsp;&nbsp;
        {download && renderExportData()}
      </Box>
      {isRolesSuccess && isRoleTargetsSuccess ? (
        <>
      <DrillDownTable
        theadings={renderHeadings('roles')}
        tdata={renderData('roles')}
      />
      </>
      ) : null }

      <Divider />
      {isVerticalsSuccess && isVerticalTargetsSuccess ? (
        <>
      <DrillDownTable
        theadings={renderHeadings(mainTable)}
        tdata={renderData(mainTable)}
      />
       </>
      ) : null }

    </VStack>
    <br/>
  </Box>
)

return (
  <Box bg="white" rounded="md" p="8px">
    {widgetMainView}
  </Box>
)
}

export default WidgetPeriodicSummary
